import { XeokitViewer } from './XeokitViewer'
import { Loader as XKTLoaderPlugin } from './XKTLoaderPlugin/Loader'
import { XKTLoadingProgress } from './XKTLoadingProgress'

// import { TrianglesInstancingLayer } from '@xeokit/xeokit-sdk/dist/xeokit-sdk.es'
import './prototypes/TrianglesInstancingLayerUpgrade'
import './prototypes/SceneModelUpgrade'

import { ElementsSelection } from '@/plugins/xeokit/selectedElements/elementsSelection'
import { SelectionFrame } from "@/plugins/xeokit/SelectionFrame/selectionFrame";
import { DistanceMeasurement } from '@/plugins/xeokit/distanceMeasurement/DistanceMeasurement'
import { ProjectionMode } from "@/plugins/xeokit/projectionMode/projectionMode";
import { XeokitSectionPlanesPlugin } from '@/plugins/xeokit/XeokitSectionPlanesPlugin/XeokitSectionPlanesPlugin'
import { ImagePlanes } from '@/plugins/xeokit/imagePlanes/imagePlanes'
import { BimLASLoaderPlugin } from "@/plugins/xeokit/LasLoaderPlugin/BimLASLoaderPlugin"
import { PickCoordinateViewer } from "@/plugins/xeokit/pickCoordinateViewer/pickCoordinateViewer";
import { ScenePick } from "@/plugins/xeokit/scenePick/scenePick"
import { SectionCube } from './sectionCube/sectionCube'
import { BimLights } from "@/plugins/xeokit/bimLights";
import { MouseNavigation } from "@/plugins/xeokit/mouseNavigation/mouseNavigation";
import { Snapshot } from './snapshot/snapshot'
import { ModelsManager } from './models/ModelsManager'
import { SectionPlanes } from './sectionPlanes/sectionPlanes'
import { CameraFlight } from "@/plugins/xeokit/cameraFlight/cameraFlight";
import { FrustumToggleElements } from "@/plugins/xeokit/frustumToggleElements/frustumToggleElements";
import { ElementsSettings } from "@/plugins/xeokit/elementsSettings/elementsSettings";
import { GlMemoryInfo } from "@/plugins/xeokit/GlMemoryInfo";
import { RegimeMeasurement } from '@/plugins/xeokit/regimeMeasurement/regimeMeasurement'
import { ObjectsMemento } from '@xeokit/xeokit-sdk'

import store from '@/store'

import { useImagePlaneStore, useRegimeMeasurementStore, useSectionCubeStore } from '@/pinia'
import { useSelectedElementsStore } from '@/pinia'
import { useSelectionFrameStore } from '@/pinia'
import { useProjectionModeStore } from '@/pinia'
import { useDistanceMeasurementStore } from '@/pinia'
import { usePickCoordinateViewerStore } from '@/pinia'
import { useMouseNavigationStore } from '@/pinia'
import { useViewerColorsStore } from '@/pinia'
import { PromiseQueue } from './PromiseQueue'
import { RevisionLoader } from './RevisionLoader'
import { Models } from './models/Models'
import { ModelQuality } from './modelQuality'

// import "./prototypes/VBOSceneModelMeshUpgrade.js"
import "./prototypes/SceneModelEntityUpgrade.js"
import "./prototypes/ObjectsMementoUpgrade.js"
import "./prototypes/TrianglesBatchingLayerUpgrade.js"
import { ModelAABBCache, ObjectAABBCache } from './modelAABB.temp'
import { PointCloudLoader } from './PointCloudLoader'
import { SceneObjects } from './sceneObjects/sceneObjects'
import { IFCGridsDrawer } from '../IFCGrids/IFCGridsDrawer'
import { PivotPinController } from './pivotPin/pivotPinController'
import { PickImagePlane } from './imagePlanes/pickImagePlane'

// import { FastNavPlugin } from './plugins/fastNavPlugin'

export class XeokitMediator {

  static #_viewer = null

  static #_xktLoader = null

  static #_lasLoader = null

  static #_sectionPlanesPlugin

  static #_loadingProgress = null

  static #_objectsMemento = new ObjectsMemento()

  static get viewer () {
    return this.#_viewer
  }

  static get xktLoader () {
    return this.#_xktLoader
  }

  static get lasLoader () {
    return this.#_lasLoader
  }

  static get loadingProgress() {
    return this.#_loadingProgress
  }

  static get objectsMemento() {
    return this.#_objectsMemento
  }

  /**
   * Режим проекции камеры
   */
  static get ProjectionMode() {
    return ProjectionMode
  }

  /**
   * Режим управления камерой
   */
  static get MouseNavigation() {
    return MouseNavigation
  }

  /**
   * Выбор Entity на сцене
   */
  static get ScenePick() {
    return ScenePick
  }

  static get ModelsManager() {
    return ModelsManager
  }

  /**
   * Выбор элементов модели
   */
  static get ElementsSelection() {
    return ElementsSelection
  }

  /**
   * Выбор элементов рамкой
   */
  static get SelectionFrame() {
    return SelectionFrame
  }

  /**
   * @api Линейка и ее параметры.
   */
  static get DistanceMeasurement() {
    return DistanceMeasurement
  }

  /**
   * Линейки
   */
  static get RegimeMeasurement() {
    return RegimeMeasurement
  }

  /**
   * Скриншоты
   */
  static get Snapshot() {
    return Snapshot
  }

  /**
   * Куб сечений
   */
  static get SectionCube() {
    return SectionCube
  }

  /**
   * Плоскости сечения
   */
  static get SectionPlanes() {
    return SectionPlanes
  }

  /**
   * Плоскость с изображением (png & jpeg)
   */
  static get ImagePlanes() {
    return ImagePlanes
  }

  /**
   * Pick плоскость с изображением (png & jpeg)
   */
  static get PickImagePlane() {
    return PickImagePlane
  }

  /** Координата */
  static get PickCoordinateViewer() {
    return PickCoordinateViewer
  }

  /** Освещение */
  static get BimLights() {
    return BimLights
  }

  /** GL Memory Info */
  static get GlMemoryInfo() {
    return GlMemoryInfo
  }

  /**
   * Реализует методы подлета камеры к цели.
   */
  static get CameraFlight() {
    return CameraFlight
  }

  /**
   * Переключение элементов в области вокруг курсора
   */
  static get FrustumToggleElements() {
    return FrustumToggleElements
  }

  /**
   * Визуальные настройки элементов
   */
  static get ElementsSettings() {
    return ElementsSettings
  }

  /**
   * Создание объектов на сцене
   */
  static get SceneObjects() {
    return SceneObjects
  }

  static get Models() {
    return Models
  }

  static get ModelQuality() {
    return ModelQuality
  }

  static get PivotPin() {
    return PivotPinController
  }

  static #_viewerPromise = null
  static #_onViewerInstall = null

  static waitForViewer () {

    if (!this.#_viewerPromise) {
      this.#_viewerPromise = new Promise(resolve => {
        this.#_onViewerInstall = resolve
      })
    }
    if (this.#_viewer) {
      this.#_onViewerInstall?.(this.#_viewer)
    }
    return this.#_viewerPromise
  }

  static #_listeners = []

  static onReady (callback) {
    this.#_viewer ? callback(this.#_viewer) : this.#_listeners.push(callback)
  }

  static #_sendOutViewer = function () {
    this.#_listeners.forEach(callback => {
      callback(this.#_viewer)
    })
    this.#_listeners = []
  }

  static installViewer (cfg = {}) {
    if (this.#_viewer) {
      this.#_viewer?.destroy()
    }

    this.#_viewer = new XeokitViewer(cfg)
    this.#_xktLoader = new XKTLoaderPlugin(this.viewer, {
      // reuseGeometries: false
    })

    this.#_sectionPlanesPlugin = new XeokitSectionPlanesPlugin(this.viewer, {
      overviewCanvasId: "mySectionPlanesOverviewCanvas",
      overviewVisible: false
    })

    SectionCube.init()
    SectionPlanes.init()
    ImagePlanes.init()
    DistanceMeasurement.init()
    
    this.#_viewer.cameraControl.on("doublePicked", () => {
      this.#_viewer.cameraControl.doublePickFlyTo = true
    });

    this.#_viewer.cameraControl.on("doublePickedNothing", () => {
      this.#_viewer.cameraControl.doublePickFlyTo = false
    });

    this.#_xktLoader.once('loadingDone', () => {
      setTimeout(() => {
        if (this.#_viewer) {
          this.CameraFlight.flyToDefaultView()
          this.PivotPin.setPivotPinPosition([0, 0, 0])
        }
      }, 10)
    })

    ScenePick.activateDefaultMouseClickedListener()

    this.#_loadingProgress = new XKTLoadingProgress()
    // this.#_storeyViewsPlugin = new StoreyViewsPlugin(this.viewer)

    // new FastNavPlugin(this.viewer, {})

    // new ViewCullPlugin(this.viewer, {
    //   maxTreeDepth: 200
    // })


    this.#_onViewerInstall?.(this.#_viewer)
    this.#_sendOutViewer()

    let lasSettings = {
      skip: 10, 
      fp64: false, 
      colorDepth: "auto"
    }
    this.initBimLasLoaderPlugin(lasSettings)

    return this.#_viewer
  }

  static initBimLasLoaderPlugin(settings) {
    this.#_lasLoader = new BimLASLoaderPlugin(this.viewer, settings)
  } 

  static reset () {
    this.uninstallInput()
    // #1. Clear Scene components
    this.#_viewer.scene.clear()

    // Wait end step #1. Otherwise, the memory is not cleared.
    // #2. Viewer destroy
    setTimeout( () => {
      this.#_viewer.destroy()
  
      this.#_viewer = null
      this.#_xktLoader = null
      // this.#_storeyViewsPlugin = null
  
      this.#_viewerPromise = null
      this.#_onViewerInstall = null
  
      this.#_listeners = []
    }, 0)

    SectionPlanes.reset()
    RegimeMeasurement.deactivate()
    SelectionFrame.deactivate()
    ImagePlanes.reset()

    useSectionCubeStore().$reset()
    useSelectedElementsStore().$reset()
    useSelectionFrameStore().$reset()
    useProjectionModeStore().$reset()
    useDistanceMeasurementStore().$reset()
    usePickCoordinateViewerStore().$reset()
    useMouseNavigationStore().$reset()
    useRegimeMeasurementStore().$reset()
    useImagePlaneStore().$reset()

    ModelAABBCache.clear()
    ObjectAABBCache.clear()
  }

  static #_input = null

  static async installInput(filePath) {
    await this.uninstallInput()
    let InputMouseController = await import(`${filePath}`)
    this.#_input = InputMouseController[Object.keys(InputMouseController)[0]]();
    this.#_input.install()
  }

  static uninstallInput() {
    if (this.#_input) this.#_input.uninstall()
  }

  static on(event, callback) {
    this.viewer.cameraControl.on(event, callback)
  }

  static fire(event, value) {
    this.viewer.cameraControl.fire(event, value)
  }

  static off(onEvent) {
    this.viewer.cameraControl.off(onEvent);
  }

  static async clearColorizeModel() {
    let noColorizeKeys = ( await useViewerColorsStore().getColorizedItemsUuid() ) || []
    let keys = this.viewer.scene.colorizedObjectIds.filter((key) => !noColorizeKeys.includes(key))
    
    XeokitMediator.ElementsSettings.setElementsColorized(keys, null)
    await useViewerColorsStore().recolorizeAll()
  }

  /** Установка настроек визуализации ревизии
     * @param {Object} cfg Настройки визуализации модели.
     * @param {String} [cfg.uuid] UUID ревизии.
     * @param {Boolean} [cfg.xrayed=false] Вкл/выкл скелет модели.
     * @param {Boolean} [cfg.visible=true] Вкл/выкл видимость модели.
     * @param {Boolean} [cfg.edges=true] Вкл/выкл грани.
     * @param {Boolean} [cfg.clearColorize=false] Сбросить цвет у элементов по умолчанию.
     * @param {Boolean} [cfg.pickable=true] Вкл/выкл выбора элементов модели.
     */
  static setRevisionVisualizationSettings(cfg = {}) {
    if (cfg.uuid) {
      let model = this.viewer.scene.models[cfg.uuid];
      model.visible = cfg.visible
      if (cfg.visible) {
        model.pickable = cfg.pickable
        model.xrayed = cfg.xrayed
        if (!cfg.xrayed) {
          model.edges = cfg.edges
        }
      }
    }
    if (cfg.clearColorize) XeokitMediator.clearColorizeModel()
  }

  static getCurrentRevisions() {
    const flatlist = store.getters['project/flatlist']
    
    const workModel = store.state.project.projectSettings.workModel
    
    // const switchonModels = workModel?.filter(model => model.switchon)
    
    let revisions = []

    // switchonModels?.forEach(({ modelUuid, convexHull }) => {
    //   const model = flatlist.find(m => m.uuid === modelUuid)
    //   if(!model) return

    //   const revision = model.revisions.current || model.revisions.last

    //   if (revision) {
    //     revisions.push({ 
    //       ...revision, 
    //       xktFile: convexHull ? revision.convexHullFile || revision.xktFile : revision.xktFile // Точная/Быстрая модели
    //     })
    //   }
    // })
    if (workModel) {
      Object.keys(workModel).forEach(modelUUID => {
        let {convexHull, switchon} = workModel[modelUUID]
        if (!switchon) return
  
        const model = flatlist.find(m => m.uuid === modelUUID)
        if (!model) return
  
        const revision = model.revision ?? null
  
        if (revision) {
          revisions.push({ 
            ...revision, 
            xktFile: convexHull ? revision.convexHullFile || revision.xktFile : revision.xktFile // Точная/Быстрая модели
          })
        }
      })
    }

    const patchList = store.state.project.patchRevList
    patchList.forEach(patch => {
      patch.showOffsetX = patch.realOffsetX
      patch.showOffsetY = patch.realOffsetY
      patch.showOffsetZ = patch.realOffsetZ
      
      patch.showEulerX = patch.realEulerX
      patch.showEulerY = patch.realEulerY
      patch.showEulerZ = patch.realEulerZ
    })
    revisions = revisions.concat(patchList)
    
    return revisions
  }

  static getCurrentModelsWithPointCloud() {
    const workModel = store?.state?.project?.projectSettings?.workModel
    if (!workModel) return [];

    let activeModelUuids = Object.keys(workModel).filter(modelUUID => workModel[modelUUID].switchon)

    const flatlist = store.getters['project/flatlist']
    if (!flatlist) return  []

    return flatlist
      .filter(model => model.pointCloud && activeModelUuids.indexOf(model.uuid) != -1)
  }

  static getModelQuality() {
    const modelQuality = store.getters['authUser/modelQuality']
    return JSON.parse(modelQuality?.value || '{}')
  }

  static getCurrentRevisionsMap() {
    const revisionsMap = {}
    const revisions = this.getCurrentRevisions()

    revisions.forEach(rev => revisionsMap[rev.uuid] = rev)

    return revisionsMap
  }

  static wasChanged = false
  static loadingActive = false

  static loadWorkModel() {
    const modeliDS = Array.prototype.concat(
      this.getCurrentRevisions().map(revision => revision.uuid), 
      this.getCurrentModelsWithPointCloud().map(model => model.pointCloud.uuid)
    )

    XeokitMediator.viewer.scene.modelIds.forEach(modelId => {      
      this.destroyUnnecessaryModel(modelId, modeliDS)
    })

    if (this.loadingActive) {
      return this.wasChanged = true
    }

    this.loadPointClouds()

    const revisions = this.getCurrentRevisions()

    this.loadSuspectElements(revisions)

    const quality = this.getModelQuality()
    // TODO добавить проверку на необходимость обновления
    XeokitMediator.xktLoader.fire('loadingStart')
    this.wasChanged = false
    this.loadingActive = true
    const queue = new PromiseQueue()
    revisions.forEach(revision => {
      queue.add(() => {
        if (this.getCurrentRevisionsMap()[revision.uuid] && !XeokitMediator.viewer.scene.models[revision.uuid]) {
          return RevisionLoader.loadRevision(revision, quality)
        }
        else {
          return Promise.resolve()
        }
      }).catch(() => {
        console.log("Принудительная отмена в очереди загрузки моделей")
      })
    })

    queue.done.then(() => {
      this.loadingActive = false
      if(store.state.IFCGrids.gridsActivity) {
        IFCGridsDrawer.clearGrids()
        store.dispatch('IFCGrids/getIFCGrids', store.state.project.project.uuid).then(data => {
          IFCGridsDrawer.drawIFCGrids(data)
        })
      }

      if (this.wasChanged) {
        this.loadWorkModel()
      }
      else {
        XeokitMediator.xktLoader.fire('loadingDone')
        XeokitMediator.objectsMemento.saveObjects(XeokitMediator.viewer.scene)

        // reset очищает viewer в setTimeout
        setTimeout(() => {
          if (this.#_viewer) {
            if (revisions.length == 1) {
              XeokitMediator.CameraFlight.flyToDefaultView()
            }
            ObjectAABBCache.clear()
            useViewerColorsStore().recolorizeAll()
          }
        })
      }
    }).catch(() => {
      console.log("Принудельная отмена завершения загрузки моделей")
    })
  }

  static loadSuspectElements(revisions) {
    // --------- Вытаскиваю подозрительные элементы для фильтрации элементов при подлете камеры
    const revisionUuids = []
    revisions.forEach(revision => {
      revisionUuids.push(revision.uuid)
    })
    store.dispatch('project/loadSuspectElements', revisionUuids)
    // ----------------------------------------------------------------------------------------
  }

  static loadPointClouds() {
    XeokitMediator.lasLoader.fire("pointCloudLoadingStart")
    const pointCloudQueue = new PromiseQueue()
    const modelWithPointCloud = this.getCurrentModelsWithPointCloud()
    modelWithPointCloud.forEach(model => {
      pointCloudQueue.add(() => !XeokitMediator.viewer.scene.models[model.pointCloud.uuid] ? this.loadPointCloud(model) : Promise.resolve())  
    }) 
    pointCloudQueue.done.then(() => {
      XeokitMediator.lasLoader.fire('pointCloudLoadingDone')
      XeokitMediator.objectsMemento.saveObjects(XeokitMediator.viewer.scene)
    })
  }

  static destroyUnnecessaryModels() {
    const revisions = Array.prototype.concat(
      this.getCurrentRevisions().map(revision => revision.uuid), 
      this.getCurrentModelsWithPointCloud().map(model => model.pointCloud.uuid)
    )
    XeokitMediator.Models.modelIds.forEach(modelId => {
      this.destroyUnnecessaryModel(modelId, revisions)
    })
  }
  static destroyUnnecessaryModel(modelId, revisions) {
    const alive = revisions.find(rev => rev === modelId)
    !alive && XeokitMediator.Models.modelsMap[modelId]?.destroy()
  }

  static reloadBimLASLoaderPlugin(settings) {
    let config = {
      skip: settings.pointInterval, 
      fp64: false, 
      colorDepth: "auto"
    }
    this.initBimLasLoaderPlugin(config)
  } 

  // методы для работы с облаками точек

  static loadWorkPointClouds() {
    const pointClouds = this.getCurrentModelWithPointCloud()
    pointClouds.forEach(pointCloud => {
      XeokitMediator.loadPointCloud(pointCloud)
    })
  }


  static loadPointCloud(model) {
    let cfg = {
      id: model.pointCloud.uuid,
      position: [model.offsetX, model.offsetY, model.offsetZ],
      rotation: model.xktversion !== 3 ? [model.eulerX, model.eulerY, model.eulerZ] : [90 + model.eulerX, model.eulerY, model.eulerZ]
    }
    
    let cloudPromise = PointCloudLoader.load(cfg)
    return cloudPromise
   
  }
  static destroyPointCloud(uuid) {
    XeokitMediator.viewer.scene?.models[uuid]?.destroy() // вынести 
  }
}