import { api } from "@/api"

const getDefaultState = () => {
    return {
        unitTypes: [],
        powerTypes: [],
    }
}

const state = getDefaultState()

export default {
    namespaced: true,

    state,

    getters: {

    },

    mutations: {
        setUnitTypes: (state, items) => {
            state.unitTypes = items
        },

        setPowerTypes: (state, items) => {
            state.powerTypes = items
        },
        
    },

    actions: {
        LOAD_UNITTYPES: ({ state, commit }) => {
            if (state.unitTypes.length === 0){
                api.elements.unitTypes().then(data => {
                    commit('setUnitTypes', data)
                })
            }
        },

        LOAD_POWERTYPES: ({ state, commit }) => {
            if (state.powerTypes.length === 0){
                api.elements.powerTypes().then(data => {
                    commit('setPowerTypes', data)
                })
            }
        },

        addAttributeElement: ({ commit },obj) => {
            commit
            // console.log(attribute);
            return api.elements.addAttributeElement(obj)
        }
    }
}