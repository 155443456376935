import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { EdgePin } from '../../distanceMeasurement/hoveringPins/edgePin'

const MOUSE_CANVAS_CLICK_TOLERANCE = 5

/*eslint-disable no-dupe-class-members*/
export class EdgeToEdgeRegime {
  static get #scene() { return XeokitMediator.viewer.scene } 

  static #originPickedEdgePin = null
  static #targetPickedEdgePin = null

  static #originEdge = null
  static #targetEdge = null

  static #edgePin = null

  static #onMouseHoverSurface = null
  static #onInputMouseUp = null
  static #onInputMouseDown = null

  static #mouseDownCanvasX
  static #mouseDownCanvasY
  static #mouseDownLeft = false
  static #mouseDownRight = false

  /**
   * Включить режим измерения между рёбрами.
   */
  static activate() {
    this.#activateInputListeners()
    this.#activateHoverListener()
  }

  /**
   * Отключить режим измерения между рёбрами.
   */
  static deactivate() {
    const input = this.#scene.input
    const cameraControl = XeokitMediator.viewer.cameraControl

    input.off(this.#onInputMouseDown)
    input.off(this.#onInputMouseUp)
    cameraControl.off(this.#onMouseHoverSurface)

    this.#edgePin?.destroy()
    this.#edgePin = null

    this.#originPickedEdgePin?.destroy()
    this.#targetPickedEdgePin?.destroy()
    this.#originPickedEdgePin = null
    this.#targetPickedEdgePin = null
  }

  static #pick(pickResult) {
    const pickedEntity = pickResult.entity
    const pickedWorldPos = pickResult.worldPos

    if (pickResult.isSectionControl) return
    if (pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')) return

    let edges = []
    pickedEntity.meshes.forEach((mesh) => {
      if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
    })
    const nearestEdgeData = geometry.nearestCoordFinder.findNearestPointOnEdgeToPoint(edges, pickedWorldPos)

    if (nearestEdgeData) {
      const p0 = nearestEdgeData.edge[0]
      const p1 = nearestEdgeData.edge[1]
      
      if (!this.#originPickedEdgePin) {
        this.#originPickedEdgePin = new EdgePin(this.#scene)
        const transform = this.#originPickedEdgePin.createTransformByTwoPoints(p0, p1)
        this.#originPickedEdgePin.update(transform)
        this.#originEdge = nearestEdgeData.edge
      } 
      else {
        if (this.#targetPickedEdgePin) this.#targetPickedEdgePin.destroy()
        this.#targetPickedEdgePin = new EdgePin(this.#scene)
        const transform = this.#targetPickedEdgePin.createTransformByTwoPoints(p0, p1)
        this.#targetPickedEdgePin.update(transform)
        this.#targetEdge = nearestEdgeData.edge

        const measurement = geometry.edgeToEdgeMeasurement.findShortestSegmentBetweenSegments(this.#originEdge, this.#targetEdge)
        XeokitMediator.DistanceMeasurement.createDistanceMeasurement({
          originWorldPos: measurement[0],
          targetWorldPos: measurement[1],
          billboard: geometry.utils.arePointsEqual(measurement[0], measurement[1], 0.00001) ? 'spherical' : 'none'
        })
      }
    }
  }

  static #activateInputListeners() {
    const input = this.#scene.input

    input.off(this.#onInputMouseDown)
    input.off(this.#onInputMouseUp)

    this.#onInputMouseDown = input.on('mousedown', (coords) => {
      this.#mouseDownCanvasX = coords[0]
      this.#mouseDownCanvasY = coords[1]
      this.#mouseDownLeft = input.mouseDownLeft
      this.#mouseDownRight = input.mouseDownRight
    })

    this.#onInputMouseUp = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#mouseDownCanvasX + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[0] < this.#mouseDownCanvasX - MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] > this.#mouseDownCanvasY + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] < this.#mouseDownCanvasY - MOUSE_CANVAS_CLICK_TOLERANCE
      ) {
        this.#mouseDownLeft = false
        this.#mouseDownRight = false
        return
      }

      if (this.#mouseDownLeft) {
        let pickResult = null
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: coords,
          pickSurface: true,
        })

        if (pickResult) this.#pick(pickResult)
      }
      
      this.#mouseDownLeft = false
      this.#mouseDownRight = false
    })
  }

  static #activateHoverListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    this.#edgePin = new EdgePin( this.#scene, { visible: false } )

    this.#onMouseHoverSurface = cameraControl.on('hover', (event) => {
      if (this.#mouseDownLeft || this.#mouseDownRight) {
        this.#edgePin.setVisible(false)
        return
      }

      const pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
        canvasPos: event.canvasPos,
      })

      if (!pickResult) return
      if (pickResult.isSectionControl) return
      if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) return 

      let edges = []
      pickResult.entity.meshes.forEach((mesh) => {
        if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
      })

      const nearestEdgeData = geometry.nearestCoordFinder.findNearestPointOnEdgeToPoint(edges, pickResult.worldPos)
      const p0 = nearestEdgeData.edge[0]
      const p1 = nearestEdgeData.edge[1]
      const transform = this.#edgePin.createTransformByTwoPoints(p0, p1)
      this.#edgePin.update(transform)
      this.#edgePin.setVisible(true)
    })
  }
}
