import { math } from "./math"
import { drawer } from "./drawer"
import { extraction } from "./extraction"
import { transform } from './transform'
import { faceToFaceMeasurement } from "./faceToFace"
import { planeToPlane } from "./planeToPlane"
import { utils } from "./utils"
import { intersection } from './intersection'
import { edgeToEdgeMeasurement } from "./edgeToEdge"
import { nearestCoordFinder } from "./nearestCoordFinder"
import { pointToFaceMeasurement } from "./pointToFace"
import { pointToEdgeMeasurement } from "./pointToEdge"
import { aabb } from "./aabb"
import { frustum } from "./frustum"
import { frustumToggleElements } from "./frustumToggleElements"
import { highPrecisionPick } from "./highPrecisionPick"
import { segments } from "./segments"

export const geometry = {
  math: math,
  drawer: drawer,
  planeToPlane: planeToPlane,
  faceToFaceMeasurement: faceToFaceMeasurement,
  edgeToEdgeMeasurement: edgeToEdgeMeasurement,
  utils: utils,
  intersection: intersection,
  extraction: extraction,
  transform: transform,
  nearestCoordFinder: nearestCoordFinder,
  pointToFaceMeasurement: pointToFaceMeasurement,
  pointToEdgeMeasurement: pointToEdgeMeasurement,
  aabb: aabb,
  frustumToggleElements: frustumToggleElements,
  frustum: frustum,
  highPrecisionPick: highPrecisionPick,
  segments: segments,
}
