
class Panel {
  constructor ({ ident, maxWidth, minWidth, minHeight, orientation, sizable, vertical = null }) {
    this.ident = ident   // попадет в атрибут id
    this.maxWidth = maxWidth ? maxWidth : window.innerWidth// максимально возможная ширина
    this.minWidth = minWidth // минимальная ширина
    this.orientation = orientation // отношение к слою - левому, правому, верхнему или нижнему
    this.sizable = sizable // изменение размера по ширине
    this.vertical = vertical ? vertical : false  // изменение размера по высоте
    this.minHeight = minHeight // минимальная высота
  }
}

// положения на экране - в котроллере такие же, хорошо бы вынести
let orientations = {
  RIGHT: "RIGHT",
  LEFT: "LEFT",
  TOP: "TOP",
  BOTTOM: "BOTTOM"
}


// константы для идентификаторов - для удобства
export const panelNames = { 
  FACECONTROL: "faceControl",
  PROCESS: "process",
  NOTIFICATION: "notification",
  TASKS: "tasks",
  TASKDETAILS: "taskdetails",
  BLUEPRINTSLIST: "blueprintslist",
  PLUGINPANEL: "pluginpanel",
  SMETAPANNEL: "smetapannel",
  WORMPANNEL: "wormpannel",
  ELEMENTTREE: "elementtree",
  PROJECTSTRUCTURE: "projectstructure",
  COLLISION: "collision",
  COLLISIONTABLE: "collisiontable",
  REPORTS: 'reports',
  ELEMENTDETAILS: 'elementdetails',
  TEMPLATES:'templates',
  CORPSTRUCTURE: 'corpstructure',
  WORKJOURNAL:'workjournal',
  FILEMANAGER: 'filemanager',
  MNEMOSCHEMAS: 'mnemoschemas',
  MAINTENANCEPANEL: 'maintenancepanel',
  MODELVISUALSETTINGS: 'modelvisualsettings',
  LOCATIONCONTROL: 'locationcontrol',

  PASSPORT: 'passport',
  STATISTICS: 'statistics',
  FLOORS: 'floors',
  FORMS: 'forms',
  API: 'api',
  MEMBERS: 'members',
  CLOUDS: 'clouds',
  WORKSPACES: 'workspaces',
  GRIDS: 'grids',
}

export const panelEnum = { 
  setup: 'setup',
  show: 'show',
  iot: 'iot',
  collision: 'collision',
  wormpanel: 'wormpanel',
  smeta: 'smeta',
  drawings: 'drawings',
  floors: 'floors',
  clouds: 'clouds',
  mnemoschemas: 'mnemoschemas',
  workspaces: 'workspaces',
  templates: 'templates',
  api: 'api',
  rights: 'rights',
  passport: 'passport',
  forms: 'forms',
  locationcontrol: 'locationcontrol',
  modelVisualSettings: 'modelVisualSettings',
  maintenance: 'maintenance',
  filemanager: 'filemanager',
  workjournal: 'workjournal',
  reports: 'reports',
  plugins: 'plugins',
  face: 'face',
  statistics: 'statistics',
  task: 'task',
  process: 'process',
  notification: 'notification',
  faceControl: 'faceControl',
  profileSettings: 'profileSettings',
  default: 'DEFAULT',
  DEFAULT: 'DEFAULT',
}

// собственно, панельки
export const panels = [

  // faceControl //
  new Panel(
    {
      ident: panelNames.FACECONTROL, 
      maxWidth: null, 
      minWidth: window.innerWidth / 6, 
      // minHeight: window.innerHeight - 45, 
      orientation: orientations.LEFT, 
      sizable: true
    }
  ),

  // process //
  new Panel(
    {
      ident: panelNames.PROCESS, 
      maxWidth: null, 
      minWidth: window.innerWidth / 6, 
      // minHeight: window.innerHeight - 45, 
      orientation: orientations.RIGHT, 
      sizable: true
    }
  ),

  // notification //
  new Panel(
    {
      ident: panelNames.NOTIFICATION, 
      maxWidth: null, 
      minWidth: window.innerWidth / 6, 
      // minHeight: window.innerHeight - 45, 
      orientation: orientations.RIGHT, 
      sizable: true
    }
  ),

  // task list //
  new Panel(
    {
      ident: panelNames.TASKS, 
      maxWidth: null, 
      minWidth: window.innerWidth / 6, 
      // minHeight: window.innerHeight - 45, 
      orientation: orientations.RIGHT, 
      sizable: true
    }
  ),

  // task details //
  new Panel(
    {
      ident: panelNames.TASKDETAILS, 
      maxWidth: null, 
      minWidth: window.innerWidth / 6, 
      // minHeight: window.innerHeight, 
      orientation: orientations.RIGHT, 
      sizable: true
    }
  ),

  // drawings //
  new Panel(  
    {
      ident: panelNames.BLUEPRINTSLIST, 
      maxWidth: null, 
      minWidth: window.innerWidth / 2, 
      minHeight: window.innerHeight, 
      orientation: orientations.LEFT, 
      sizable: true
    }
  ),  

  // plugins //
  new Panel(
    {
      ident: panelNames.PLUGINPANEL,
      maxWidth: null, 
      minWidth: window.innerWidth / 3 - 45,
      minHeight: window.innerHeight, 
      orientation: orientations.LEFT, 
      sizable: true
    }
  ),

  //templates//
  new Panel(
    {
      ident: panelNames.TEMPLATES,
      maxWidth: null, 
      minWidth: 520,
      minHeight: window.innerHeight, 
      orientation: orientations.LEFT, 
      sizable: true
    }
  ),

  // smeta //
  new Panel(
    {
      ident: panelNames.SMETAPANNEL, 
      maxWidth: null, 
      minWidth: window.innerWidth / 2 - 45,
      minHeight: window.innerHeight, 
      orientation: orientations.LEFT, 
      sizable: true
    }
  ),

  // worm //
  new Panel(
    {
      ident: panelNames.WORMPANNEL, 
      maxWidth: null, 
      minWidth: window.innerWidth / 2 - 45,
      minHeight: window.innerHeight, 
      orientation: orientations.LEFT, 
      sizable: true
    }
  ),

  // element tree //
  new Panel(
    {
      ident: panelNames.ELEMENTTREE, 
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable:false
    }
  ),

  // project structure //
  new Panel(
    {
      ident: panelNames.PROJECTSTRUCTURE, 
      maxWidth: null,
      minWidth: 16,  // in percent
      minHeight: window.innerHeight,
      orientation: orientations.LEFT,
      sizable: false
    }
  ),

  // collisions //
  new Panel(
    {
      ident: panelNames.COLLISION,
      maxWidth: null,
      minWidth: window.innerWidth / 4,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT,
      sizable: true
    }
  ),

  // collisions table // 
  new Panel(
    {
      ident: panelNames.COLLISIONTABLE,
      maxWidth: null,
      minWidth: window.innerWidth / 100 * 75 - 112,
      minHeight: 340,
      orientation: orientations.BOTTOM, 
      sizable: false, 
      vertical: true
    }
  ),

  // reports //
  new Panel(
    {
      ident: panelNames.REPORTS,
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  // project corp structure //
  new Panel(
    {
      ident: panelNames.CORPSTRUCTURE, 
      maxWidth: null,
      minWidth: 16,  // in percent
      minHeight: window.innerHeight,
      orientation: orientations.LEFT,
      sizable: false
    }
  ),

  // work journal //
  new Panel(
    {
      ident: panelNames.WORKJOURNAL,
      maxWidth: null, 
      minWidth: window.innerWidth / 2,
      minHeight: window.innerHeight, 
      orientation: orientations.LEFT, 
      sizable: true
    }
  ),

  // file manager //
  new Panel(
    {
      ident: panelNames.FILEMANAGER,
      maxWidth: null, 
      minWidth: window.innerWidth / 3,
      minHeight: window.innerHeight, 
      orientation: orientations.LEFT, 
      sizable: true
    }
  ),

  new Panel(
    {
      ident: panelNames.MAINTENANCEPANEL, 
      maxWidth: null, 
      minWidth: window.innerWidth / 2 - 45,
      minHeight: window.innerHeight, 
      orientation: orientations.LEFT, 
      sizable: true
    }
  ),
  new Panel(
    {
      ident: panelNames.MNEMOSCHEMAS,
      maxWidth: null,
      minWidth: window.innerWidth / 2,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT,
      sizable: true
    }
  ),
  new Panel(
    {
      ident: panelNames.MODELVISUALSETTINGS,
      maxWidth: null,
      minWidth: 27,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT,
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.LOCATIONCONTROL,
      maxWidth: null,
      minWidth: 18,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT,
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.PASSPORT,
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.STATISTICS,
      maxWidth: null,
      minWidth: 50,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.FLOORS,
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.FORMS,
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.API,
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.MEMBERS,
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.CLOUDS,
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.WORKSPACES,
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  new Panel(
    {
      ident: panelNames.GRIDS,
      maxWidth: null,
      minWidth: 25,
      minHeight: window.innerHeight,
      orientation: orientations.LEFT, 
      sizable: false
    }
  ),

  // // element details //
  // new Panel(
  //   {
  //     ident: panelNames.ELEMENTDETAILS,
  //     maxWidth: null,
  //     minWidth: window.innerWidth / 4,
  //     minHeight: window.innerHeight,
  //     orienttation: orientations.LEFT, 
  //     sizable: false,
  //     vertical: true
  //   }
  // )

]


