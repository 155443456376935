import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import store from '@/store'
import { HighPrecisionPick } from '@/plugins/xeokit/HighPrecisionPick.js'
import { useSearchElementsStore } from '@/pinia/searchElements.module'
import { useViewerGroupsStore } from '@/pinia'

/*eslint-disable no-dupe-class-members*/
export class ScenePick {
  
  static #_mouseClick = null
  static #_keyDown = null
  static #_defaultMouseClickedListener = () => {}
  static #_mouseClickListener = () => {}
  static #_keyDownListener = () => {}
  static get #_pickedElement() { return XeokitMediator.ElementsSelection.pickedElement }
  static get #_selectedElements() { return XeokitMediator.ElementsSelection.selectedElements }

  /**
   * Пытается выбрать {@link Entity} на сцене.
   *
   * Игнорирует {@link Entity}s, у которых {@link Entity#pickable} установлено ``false``.
   *
   * @param {*} params Параметры выбора.
   * @param {Boolean} [params.pickSurface=false] Следует ли находить выбранное положение на поверхности объекта.
   * @param {Boolean} [params.pickSurfaceNormal=false] Следует ли найти выбранную нормаль на поверхности объекта. Работает только в том случае, если задано значение ``pickSurface``.
   * @param {Number[]} [params.canvasPos] Координаты canvas. При выборе луча это переопределит параметры **origin** и **direction** и приведет к тому, что луч будет запущен через холст в эту позицию, непосредственно вдоль оси Z отрицательного пространства просмотра.
   * @param {Number[]} [params.origin] Исходная позиция луча в мировом пространстве. Игнорируется при задании canvasPos.
   * @param {Number[]} [params.direction] Направление луча в мировом пространстве. Также подразумевает длину луча. Игнорируется при задании canvasPos.
   * @param {Number[]} [params.matrix] 4x4 матрица преобразования для определения origin и direction луча, альтернативно ``origin`` and ``direction``.
   * @param {String[]} [params.includeEntities] Uuid {@link Entity}s, доступных для выбора. Если задано, игнорирует {@link Entity}s, Uuid которых не включены в этот список.
   * @param {String[]} [params.excludeEntities] Uuid {@link Entity}s, недоступных для выбора. Если задано, выбор произойдет *через* эти {@link Entity}s, будто их нет.
   * @param {PickResult} [pickResult] Содержит результат выбора. Будет использоваться singleton PickResult сцены, если вы не предоставите свой собственный.
   * @returns {PickResult} Содержит результат выбора, возвращает выбранный {@link Entity}, если он найден, иначе null. Описание доступно в комментариях к методу pick.
   */
  static pickResult(params, pickResult) {
    return XeokitMediator.viewer.scene.pick(params, pickResult)
  }

  static highPrecisionPickResult(params) {
    params.pickSurface = false
    params.pickSurfaceNormal = false
    return HighPrecisionPick.pick(params)
  }

  static deactivateDefaultMouseClickedListener() {
    XeokitMediator.viewer.scene.input.off(this.#_defaultMouseClickedListener)
    XeokitMediator.off(this.#_mouseClickListener)
  }

  static pickElementByUuid(uuid) {
    this.#_searchElementsStore.pickElementByUuid(uuid)
  }

  static get #_searchElementsStore() {
    return useSearchElementsStore()
  }

  static showAllObjects () {
    useViewerGroupsStore().showAllObjects()
    store.commit('projectDict/setShowPanel', false)
    store.commit('smeta/setShowPanelHightLight', false)
    store.commit('worm/setShowPanelHightLightGroups', false)
  }

  static activateDefaultMouseClickedListener() {
    this.#_mouseClickListener = XeokitMediator.on('mouseClick', (mouseClick) => {
      this.#_mouseClick = mouseClick
    })

    this.#_keyDownListener = XeokitMediator.viewer.scene.input.on('keydown', (keyDown) => {
      if (keyDown == 27) {
        this.showAllObjects()
      }
    })

    this.#_defaultMouseClickedListener = XeokitMediator.viewer.scene.input.on('mouseclicked', (canvasCoords) => {
      XeokitMediator.viewer.cameraControl.scene.canvas.canvas.style.cursor = 'default'

      if (
        XeokitMediator.SectionPlanes.active ||
        XeokitMediator.RegimeMeasurement.isRegimeMeasurementActive ||
        XeokitMediator.PickCoordinateViewer.isPickCoordinateViewerActive ||
        XeokitMediator.PickImagePlane.isWaitImagePlanePick
      ) {
        return false
      }

      let pickResult = this.pickResult({
        canvasPos: canvasCoords,
        pickSurface: true,
      })
      if (pickResult) {
        const entity = pickResult.entity
        if (this.#_mouseClick && this.#_mouseClick.buttons == 1) {
          
          if (this.#_mouseClick.shiftKey) {
            const isSelected = this.#_selectedElements?.includes(entity.id)
            if (!isSelected) store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', entity.id)
            XeokitMediator.ElementsSelection.addSelectedElement(entity.id)
            XeokitMediator.ElementsSelection.pickElement(entity.id)
          } 
          else if (this.#_mouseClick.ctrlKey) {
            const isSelected = this.#_selectedElements?.includes(entity.id)
            if (isSelected) store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', entity.id)
            XeokitMediator.ElementsSelection.removeSelectedElement(entity.id)
            XeokitMediator.ElementsSelection.pickElement(null)
          }
          else {
            if (XeokitMediator.ElementsSelection.selectedElements.length <= 1) {
              if (this.#_pickedElement) {
                // Случай, когда selected не совпадал с picked во время выбора элемента - новые элементы становятся только picked,
                // пока пользователь не выберет selected элемент
                if (entity.id != this.#_selectedElements[0] && this.#_selectedElements[0] != this.#_pickedElement) {
                  XeokitMediator.ElementsSelection.selectElements([XeokitMediator.ElementsSelection.selectedElements[0]])
                }

                // Случай, когда пользователь выбрал picked элемент
                else if (this.#_pickedElement == entity.id) {
                  XeokitMediator.ElementsSelection.selectElements([])
                  store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', XeokitMediator.ElementsSelection.pickedElement)
                } 
                else {
                  // Случай, когда selected и picked не совпадают, а пользователь выбирает selected элемент
                  if (XeokitMediator.ElementsSelection.selectedElements[0] == entity.id) {
                    void(0)
                  }

                  // Обычный случай, когда и selected и picked совпадали до выбора нового элемента
                  else {
                    store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', entity.id)
                    store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', XeokitMediator.ElementsSelection.pickedElement)
                  }

                  XeokitMediator.ElementsSelection.selectElements([entity.id])
                }
              } 
              else {
                XeokitMediator.ElementsSelection.selectElements([entity.id])
                store.dispatch('axis/tree/toggleNodeSelectionByNodeUuid', entity.id)
              }
            }

            XeokitMediator.ElementsSelection.pickElement(entity.id)
          }

          if (XeokitMediator.ElementsSelection.pickedElement) {
            store.dispatch('axis/tree/fetchElementByGlobalId', XeokitMediator.ElementsSelection.pickedElement) //TODO: УБРАТЬ
            this.pickElementByUuid(XeokitMediator.ElementsSelection.pickedElement)
          } 
          else {
            store.dispatch('axis/tree/fetchElementByGlobalId', null) //TODO: УБРАТЬ
            this.pickElementByUuid(null)
          }
        }
      } 
      else {
        store.dispatch('axis/tree/fetchElementByGlobalId', null) //TODO: УБРАТЬ
        if (this.#_pickedElement) {
          XeokitMediator.ElementsSelection.pickElement(null)
        }
      }
    })
  }


}
