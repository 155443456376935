import { defineStore } from 'pinia'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import vuexStore from '@/store'

export const ViewerPlugin = Object.freeze({
  NONE: 'none',
  MEASUREMENTS: 'measurements',
  COORDINATE_PICKER: 'coordinatePicker',
  SECTION_PLANES: 'sectionPlanes',
  SECTION_CUBE: 'sectionCubes',
})

export const useViewerPluginsStore = defineStore('viewer.plugins', {
  state: () => {
    return {
      activeTab: ViewerPlugin.MEASUREMENTS,
    }
  },

  getters: {
    activePlugin: () => {
      if (XeokitMediator.PickCoordinateViewer.isPickCoordinateViewerActive) return ViewerPlugin.COORDINATE_PICKER
      else if (XeokitMediator.RegimeMeasurement.isRegimeMeasurementActive) return ViewerPlugin.MEASUREMENTS
      else if (XeokitMediator.SectionPlanes.active) return ViewerPlugin.SECTION_PLANES
      else if (XeokitMediator.SectionCube.active) return ViewerPlugin.SECTION_CUBE
      else return ViewerPlugin.NONE
    },
    isNewSectionPlaneToolActive: () => XeokitMediator.SectionPlanes.active,
    isCoordsPickerActive: () => XeokitMediator.PickCoordinateViewer.isPickCoordinateViewerActive,
    isPluginsDisabled: () => vuexStore.getters['project/atModelPositioning'],
  },

  actions: {
    setActiveTab(tab) {
      if (tab == this.activeTab) return

      this.deactivateAll()

      if (this.activeTab == ViewerPlugin.SECTION_CUBE || tab == ViewerPlugin.SECTION_CUBE) {
        this.toggleSectionCubePlugin()
      }
      // if (this.activeTab == ViewerPlugin.COORDINATE_PICKER || tab == ViewerPlugin.COORDINATE_PICKER) {
      //   this.toggleCoordinatePickerPlugin()
      // }
      this.activeTab = tab
    },

    deactivateAll() {
      XeokitMediator.PickCoordinateViewer.pickCoordinateViewerActivity(false)
      XeokitMediator.SectionPlanes.active = false
      XeokitMediator.SectionCube.setActive(false)
      XeokitMediator.RegimeMeasurement.deactivate()
    },

    toggleMeasurementsPlugin () {
      // XeokitMediator.PickCoordinateViewer.pickCoordinateViewerActivity(!XeokitMediator.PickCoordinateViewer.isPickCoordinateViewerActive)
    },

    toggleCoordinatePickerPlugin () {
      XeokitMediator.PickCoordinateViewer.pickCoordinateViewerActivity(!XeokitMediator.PickCoordinateViewer.isPickCoordinateViewerActive)
    },
    
    activateCoordinatePickerPlugin() {
      XeokitMediator.PickCoordinateViewer.pickCoordinateViewerActivity(true)
    },

    toggleSectionPlanesPlugin () {
      XeokitMediator.SectionPlanes.active = !XeokitMediator.SectionPlanes.active
    },
    
    activateSectionPlanesPlugin() {
      XeokitMediator.SectionPlanes.active = true
    },

    toggleSectionCubePlugin () {
      XeokitMediator.SectionCube.setActive(!XeokitMediator.SectionCube.active)
      
      // if (XeokitMediator.SectionCube.active) {
      //   // const selectedElements = XeokitMediator.viewer.scene.selectedObjectIds.concat(XeokitMediator.viewer.scene.highlightedObjectIds)
      //   // if (!selectedElements.length) return
  
      //   // let aabb = XeokitMediator.viewer.scene.getAABB(selectedElements).map((num, idx) => idx < 3 ? num - 0.1 : num + 0.1)
      //   // XeokitMediator.CameraFlight.flyTo({ aabb })
  
      //   // XeokitMediator.SectionCube.setAabb(aabb)
      //   this.runSectionCubeActivationActivities()
      // }
    },

    activateSectionCubePlugin() {
      XeokitMediator.SectionCube.setActive(true)
    },
    
    deactivateSectionCubePlugin() {
      XeokitMediator.SectionCube.setActive(true)
    },

    runSectionCubeActivationActivities() {
      const selectedElements = XeokitMediator.viewer.scene.selectedObjectIds.concat(XeokitMediator.viewer.scene.highlightedObjectIds)
      if (!selectedElements.length) return

      let aabb = XeokitMediator.viewer.scene.getAABB(selectedElements).map((num, idx) => idx < 3 ? num - 0.1 : num + 0.1)
      XeokitMediator.CameraFlight.flyTo({ aabb })
      XeokitMediator.SectionCube.setAabb(aabb)
    }
  }
})
