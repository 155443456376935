<template lang="pug">
  v-app
    router-view
    .alerts(:style="alertsStyle")
      v-alert(v-for="(a) in reverseAlerts" :key="a.time" :id="`alert-${a.time}`" data-aos="fade-left" :value="!!a.info" border="left" :type="a.type" min-width="400" elevation="2" transition="scale-transition" dense dismissible ) 
        b {{ a.info }}
    
    app-dev
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AppDev from '@/components/project/common/Dev.vue'
import i18n from '@/plugins/i18n'

export default {
  data () {
    return {
      timerId: null,
      timerAlertId: null,
      alertList: [],
      alertsStyle: null,
    }
  },

  components: {
    AppDev
  },

  beforeCreate(){
    i18n.loadDefaultLocale()
  },

  computed: {
    ...mapGetters('error', ['errorInfo']),
    ...mapState('alert', ['alert']),

    reverseAlerts () {
      return [...this.alertList].reverse()
    }
  },

  watch: {

    alert (val) {
      if (!val || this.alertList.find(a => a === val)) return

      val['time'] = new Date().getTime()
      val['timerAlertId'] = setTimeout(() => {
        this.clearAlert()
        this.alertList.filter(a => {
          if (new Date().getTime() > a.time + 3000) {
            var element = document.getElementById("alert-" + a.time)
            element.setAttribute('data-aos',  "fade-up")
            element.classList.remove("aos-animate")
          }
        })

        setTimeout(() => {
          this.alertList = this.alertList.filter(a => new Date().getTime() < a.time + 3000)
        }, 300)
        
      }, 3000)

      this.alertList = [...this.alertList, val]
    }
  },

  methods: {
    ...mapActions('alert', ['clearAlert']),
  }
}
</script>

<style scoped>
.alerts {
  background: transparent;
  position: fixed;
  right: 10px;
  z-index: 1000;
  bottom: 50px;
}
</style>