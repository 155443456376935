<template lang="pug">
  v-toolbar#page-header.bg-none(dense flat height="32" style='background: transparent !important')
    .project-toolbar-container.base-scroll-body(style="overflow-y: hidden;")

      .d-flex.project-toolbar.align-center(:style='toolbarStyle' style="min-width:260px")
        div(v-if="$app.dev.isShowLogo")
          div.marg.pl-2(test-id="scrolltop")
            div(v-if="newYear")
              app-icon-action.pointer( v-if="$vuetify.theme.isDark" icon="$bimit-log-new-year-light-icon" size="54" test-id="to-dashboard" @click="toDashboard()")
              app-icon-action.pointer( v-if="!$vuetify.theme.isDark && themeName =='light'" icon="$bimit-log-new-year-dark-icon" size="54" test-id="to-dashboard" @click="toDashboard()")
              app-icon-action.pointer( v-if="!$vuetify.theme.isDark && themeName == 'grey'" icon="$bimit-log-new-year-light-icon" size="54" test-id="to-dashboard" @click="toDashboard()")
            div(v-else)
              router-link(:to='dashboardPath' style="text-decoration: none; color: inherit;")
                app-icon-action.pointer( v-if="!$app.version.isCloud" icon="$logo-bimit-new-icon" size="48" test-id="to-dashboard")
                app-icon-action( v-else icon="$logo-bimit-new-icon" size="48")
         
        .d-flex.justify-end( style="max-width:100px" )
          router-link(:to='dashboardPath' style="text-decoration: none; color: inherit;")
            app-toolbar-button( v-if="!$app.version.isCloud" icon="$dashboard-icon" :active="openedPage('dashboard')" :title="$t('headerToolbar.dashboard')" @click="toDashboard()" test-id="to-projects" )
          
          // !!! Александр сказал скрыть, пока не востребовано 08.06.2024
          //- app-toolbar-button( v-if="!$app.version.isCloud && haveCloud" icon="$cloud-icon" :active="openedPage('cloud')" :title="$t('headerToolbar.cloud')" @click="goToCloud()")
          
          div(v-if="!$app.version.isCloud && haveCompany")
            router-link( v-if="user.adminOrgs.length == 1" :to='orgPath' style="text-decoration: none; color: inherit;")
              app-toolbar-button( icon="$groups-icon" :active="openedPage('corp')" :title="user.adminOrgs[0].title" @click="gotoOrg( user.adminOrgs[0] )")
            router-link( v-else-if="user.adminOrgs.length > 1" :to='orgPath' style="text-decoration: none; color: inherit;")
              app-toolbar-item.pa-0( :items="user.adminOrgs" key-name="title" min-width="32" @select="gotoOrg($event)" ) 
                app-toolbar-button( v-if="!$app.version.isCloud && haveCompany" icon="$groups-icon" :active="openedPage('corp')" :title="$t('drawer.organizations')")

          router-link( v-if="!$app.version.isCloud && isAdmin" :to='adminPath' style="text-decoration: none; color: inherit;")
            app-toolbar-button(icon="$admin-panel-icon" :active="openedPage('admin')" :title="$t('profile.action.items.adminPanel')" @click="goToAdmin()")
          
          // !!! Александр сказал скрыть, пока не востребовано 08.06.2024
          //- app-toolbar-button( icon="$task-manager-icon" :active="openedPage('task/manager')" :title="$t('module.task.taskManager.manager')" @click="openTaskManager()")

      .d-flex.project-toolbar.align-center.ml( :style="toolbarStyle + 'margin-left: 2px;'" )
        slot( name="headerContentStart" )
        .d-flex.align-center.mr-4( v-if="title != null" )
          .toolbar-title.pointer.ml-4 {{ title }}

        slot( name="headerContentCenter" )
        
        .d-flex.align-center.justify-end.mr-4
          div#page-header-search.mr-auto( v-if="search" style="border-radius: 2px;")
            v-text-field(ref="searchField" :height="$style.searchHeight" :placeholder="searchTitle" 
              rounded hide-details @input="changeHandler" :value="searchValue" @focus="onFocus" @blur="onBlur" )
              template(slot="append")
                v-icon.mr-n3( size="18" color="var(--v-primary-darken2)") mdi-magnify

          slot( name="headerContentEnd" )


      .d-flex.project-toolbar.align-center( v-if="afterContent" :style="toolbarStyle + 'margin-left: 2px;'" )
        slot( name="headerAfterContent" )


      .d-flex.project-toolbar.align-center.ml( :style="toolbarStyle + 'margin-left: 2px;'" )
        
        .d-flex.align-center( v-if="profile" )

          div.relative(v-if="$route.path.includes('dashboard') || ($router.path === '/project' && projectUuid )")
            .d-flex.justify-center.align-center.ml-3.mr-2( :class="{ 'toolbar-btn-active': notificationFlagWithDashboadr }" )
              app-toolbar-button( icon="$notification-icon" iconSize="32px" :badge ="hasNotification" :active="notificationFlagWithDashboadr" @click="openNotificationPanel" :title="$t('projectToolbarMenu.notifications')" test-id="layer-top-notif-btn")

          .d-flex.align-center.header-judge__name.mw-200.ml-4(v-if="user" @click="openActionProfile") 
            app-avatar.mr-2.mt-1.mt-sm-0( :url="avatar" :colorObj="getBgColor(user.uuid)" :text="user.name" style="max-width:24px" )
            .user-title.mb-2 {{ user.name }}
          v-progress-circular(v-else size="24" width="2" indeterminate)

          .d-flex.justify-end.mr-5( style="max-width:24px" )
            app-toolbar-button( icon="$logout-icon" @click="logout()" :title="$t('profile.action.items.logout')" ) 

        .d-flex.align-center( v-else )
          slot( name="headerEnd" )

    action-profile(v-if="$route.path.includes('admin') && dialog.action" v-model="dialog.action")

</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { authUserService } from "@/_services";
import ActionProfile from '@/components/access/ActionProfile' 
import DashboardToolbar from "@views/dashboard/DashboardToolbar"
import uniqolor from 'uniqolor';
import { SourcePath } from '@app/SourcePath'
import { App } from '@app/App'

export default {
  name: "AppPageHeader",

  components: {
    [ActionProfile.name]: ActionProfile,
    DashboardToolbar
  },

  props: {
    showUser: {
      type: Boolean,
      default: true
    },
    searchValue: {
      type: String,
      default: ""
    },
    profile: null,
    afterContent: null,
    title: null,
    search: null,
    leftPanel: null,
    searchTitle: null,
    opacity: Boolean,
  },

  data() {
    return {
      avatar: null,
      dialog: {
        action: false,
        changePassword: false,
        editUser: false,
        setting: false
      },
      isFocused: false,
      notificationFlagWithDashboadr: false,
      newYear: false,
    };
  },


  watch: {
    user () {
      this.avatar = SourcePath.avatar(this.user.avatar)
    },

    orgs() {
      this.user.adminOrgs = this.orgs.filter((element) => element.admins.includes(this.user.uuid) ) 
    }
  },  

  mounted () {
    document.addEventListener("visibilitychange", () => {
      const logoutChannel = new BroadcastChannel('logoutChannel');
      logoutChannel.onmessage = function (event) {
        if (event.data === 'logout' && document.hidden) {
          authUserService.logout()
          App.auth.clear()
        }
      }
      window.addEventListener('beforeunload', function () {
        logoutChannel.close()
      })
    })

    window.addEventListener('keydown', this.focusHandler, true)

    this._setTheme(localStorage.getItem('themeName')?.toUpperCase() || 'DARK', false)
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.focusHandler, true)
    this.notificationFlagWithDashboadr = false
    this.setNotificationWithDashboard(this.notificationFlagWithDashboadr)
    this.profileInfo(false)
  },

  computed: {
    ...mapState('authUser', ['user']),
    ...mapState('users', ['actionProf']),
    ...mapGetters('corp', ['orgs']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters('notification', ['hasNotification']),
    ...mapGetters("theme", ['themeName']),

    isAdminPanel() {
      return this.$route.matched[0].path === "/admin" ? true : false
    },

    isAdmin() {
      return App.auth.isAdmin
    },

    dashboardPath() {
      return `/dashboard/`
    },

    adminPath() {
      return `/admin/users`
    },

    orgPath() {
      return `/corp/${this.user.adminOrgs[0].uuid}`
    },

    toolbarStyle() {
      if (this.opacity) {
        let hex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.$vuetify.theme.themes.dark.toolbar.base);
        if (!hex) {
          return "background: var(--v-primary-darken2);"
        }
        let rgb = `${parseInt(hex[1], 16)}, ${parseInt(hex[2], 16)}, ${parseInt(hex[3], 16)}, 0.6`

        return `background: rgba(${rgb});`
      }
      return "background: var(--v-primary-darken2);"
    },

    haveCompany() {
      return this.user.adminOrgs && this.user.adminOrgs !== undefined && this.user.adminOrgs.length>0
    },

    haveCloud() {
      return this.user.cloudOrg !== undefined && this.user.cloudOrg && this.user.cloudOrg.cloudUrl && this.user.cloudOrg.cloudUrl!=""
    },
  },

  methods: {
    ...mapMutations('theme', ['setTheme']),
    ...mapMutations("authUser", ["setUser"]),
    ...mapMutations('notification', ['setNotificationWithDashboard']),
    ...mapMutations('users', ['profileInfo']),

    openNotificationPanel() {
      if (this.actionProf) this.openActionProfile()
      this.notificationFlagWithDashboadr = !this.notificationFlagWithDashboadr
      this.setNotificationWithDashboard(this.notificationFlagWithDashboadr)
    },
    
    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    toDashboard () {
      if(location.pathname != `/dashboard`){
        this.$router.push({ path: `/dashboard` })                                                           
      }
      
    },

    focusHandler (event) {
      let codes = [113]
      if (!this.isFocused && codes.includes(+event.keyCode)) {
        this.focusOnSearch()
        event.preventDefault()
        event.stopPropagation()
      }
    },

    focusOnSearch () {
      this.$refs.searchField?.focus()
    },

    onFocus () {
      this.isFocused = true
    },

    onBlur () {
      this.isFocused = false
    },

    changeHandler(text) {
      this.$emit("search", text);
    },

    gotoOrg (org) {
      if (location.pathname != ('/corp/'+org.uuid)) {
        this.$router.push({ path: ('/corp/'+org.uuid) })
        this.$store.dispatch('corp/LOAD_ORG_FULL', org.uuid)
      }
    },

    goToAdmin () {
      if (location.pathname != '/admin/users') {
        this.$router.push({ path: '/admin/users' })
      }
    },

    openedPage(page) {
      return location.pathname.includes(page)
    },

    clear() {
      this.$store.commit('project/resetState')
      this.$store.commit('task/resetState')
    },

    openTaskManager() {
      this.clear()
      if (window.location.href.includes('/task/manager')) {
        return
      }
      if (this.projectUuid) {
        this.$router.push(`/task/manager?project='${this.projectUuid}'`)
      }
      else {
        this.$router.push('/task/manager')
      }
    },


// === Functions By Profile ===
    logout() {
      const logoutChannel = new BroadcastChannel('logoutChannel');
      logoutChannel.postMessage('logout')
      logoutChannel.close()
      this.setUser({name: null})
      authUserService.logout()
      App.auth.clear()
      //window.location = App.social.authserver+"/logout?logout_uri="+App.social.logout;
    },

    openActionProfile() {
      if (!this.user) this.$store.dispatch('authUser/LOAD_CURRENT_USER')
      if (this.$route.path.includes('admin')) {
        this.dialog.action = true
      } else {
        if (this.notificationFlagWithDashboadr) this.openNotificationPanel()
        this.profileInfo(!this.actionProf)
      }
    },

    goToCloud () {
      document.location.href = this.user.cloudOrg.cloudUrl;
    }

  },
  
};
</script>

<style lang="scss" module>
$app-header-search-height: 24;

:export {
  color: #c4c4c4;
  searchHeight: $app-header-search-height;
  searchIconSize: $app-header-search-height - 8;
}
</style>

<style lang="scss" scoped>
.project-toolbar-container.app-toolbar-item__activator {
  padding: 0;
}
.header {
  z-index: 5;
  height: 80px;
  margin-top: -16px;
  .header-row {  
    position: fixed;
    z-index: 5;
    height: 80px;
    padding-top: 16px;
  }

  .header-row-width-admin-panel {
    width: calc(100% - 112px);
  }

  .header-row-width-normal {
    width: calc(100% - 32px);
  }
}

.header-judge__wrapper {
  display: flex;
}
.header-judge__name {
  font: normal 14px/32px $roboto;
  color: var(--v-surface-lighten3);
  cursor: pointer;
  overflow: hidden;
  flex: 1 1 auto;
}

.mw-200 {
  min-width: 200px;
}

.theme--dark.v-toolbar.v-sheet, .bg-none {
  background: none;
}

.project-toolbar-container {
  height: 32px;
  background: transparent; // цвет между блоками
  display: flex;
  text-align: center;
  flex-grow: 1;
  // overflow-x: auto;
  // overflow-y: hidden;
  // overflow: hidden;
  // margin-top: -16px;
  // margin-left: -40px;
  // margin-right: 8px;
}

.project-toolbar {
  background-color: var(--v-surface-base) !important; // основной bg 
  color: var(--v-primary-darken2);
}

.project-toolbar div {
  color: var(--v-surface-lighten3);
}

.project-toolbar-container div:nth-child(1) {
  flex: 3;
}
.project-toolbar-container div:nth-child(2) {
  flex: 8;
}
.project-toolbar-container div:nth-child(3) {
  flex: 1;
}

.mr-22 {
  margin-right: 88px;
}

.toolbar-title {
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: start;
  text-transform: uppercase;
}
.user-title {
  text-align: start;
  max-height: 24px;
}
.toolbar-btn {
  max-width: 32px;
  width: 32px;
  height: 32px;
  // border-radius: 2px;
  &:hover {
    background-color: var(--v-surface-darken3);
    opacity: 0.7;
  }
}

.toolbar-btn-active {
  background-color: var(--v-tertiary-base);
  border: 1px solid rgb(117, 117, 117, .9);
}

#page-header ::v-deep .v-toolbar__content, .v-toolbar__extension {
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}
#page-header-search{
  // border: 1px solid var(--v-primary-darken2);
  background-color: var(--v-surface-lighten1) !important;
  // border-radius: 20px !important;
  min-width: 200px;
  max-width: 300px
}

#page-header-search .v-input {
  caret-color: var(--v-primary-darken2) !important;
}

#page-header-search .v-input ::placeholder {
  color: var(--v-primary-darken2) !important;
}

.marg {
  width: max-content;
}
.ml{
  margin-left: 2px;
}
</style>
