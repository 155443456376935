import { geometry } from '@/plugins/xeokit/plugins/geometry/geometry'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { VertexPin } from '../../distanceMeasurement/hoveringPins/vertexPin'
import { FacePin } from '../../distanceMeasurement/hoveringPins/facePin'
import { AlertService } from '@/assets/app/AlertService'
import i18n from '@/plugins/i18n'

const MOUSE_CANVAS_CLICK_TOLERANCE = 5
const DISTANCE_COEFF = 0.05

/*eslint-disable no-dupe-class-members*/
export class VertexToFaceRegime {
  static #firstPoint = null

  static #pickingPoint = true

  static #originPickedVertexPin = null
  static #targetPickedFacePin = null

  static #vertexPin = null
  static #facePin = null

  static #mouseHoverListenerId = null
  static #mouseUpListenerId = null
  static #mouseDownListenerId = null

  static #mouseDownCanvasX
  static #mouseDownCanvasY
  static #mouseDownLeft = false
  static #mouseDownRight = false

  /**
   * Включить режим измерения между вершиной и гранью.
   */
  static activate() {
    this.#activateInputListeners()
    this.#activateHoverListener()
  }
  
  /**
   * Отключить режим измерения между вершиной и гранью.
   */
  static deactivate() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#mouseDownListenerId)
    input.off(this.#mouseUpListenerId)
    cameraControl.off(this.#mouseHoverListenerId)

    this.#pickingPoint = true

    this.#firstPoint = null
    
    this.#vertexPin?.destroy()
    this.#vertexPin = null

    this.#originPickedVertexPin?.destroy()
    this.#originPickedVertexPin = null

    this.#facePin?.destroy()
    this.#facePin = null

    this.#targetPickedFacePin?.destroy()
    this.#targetPickedFacePin = null
  }

  static #pick(pickResult) {
    const pickedEntity = pickResult.entity
    const pickedWorldPos = pickResult._worldPos

    const pickedFaceTriangles = pickResult.trianglesPositions
    const pickedFaceEdges = pickResult.faceEdges

    if (pickResult.isSectionControl) return
    if (pickedEntity?.meshes[0]?.id?.toString().includes('pointsMesh')) return

    let edges = []
    pickedEntity.meshes.forEach((mesh) => {
      if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
    })

    if (!this.#firstPoint) {
      this.#firstPoint = geometry.nearestCoordFinder.findNearestCornerByEdges(pickedWorldPos, edges)

      const scale = geometry.math.distance(XeokitMediator.viewer.camera.eye, this.#firstPoint) * DISTANCE_COEFF
      this.#originPickedVertexPin = new VertexPin( XeokitMediator.viewer.scene )
      this.#originPickedVertexPin.update({
        position: this.#firstPoint,
        scale: [scale, scale, scale]
      })

      this.#pickingPoint = false
      this.#vertexPin.setVisible(false)
    } 
    else {
      this.#originPickedVertexPin.setVisible(false)

      this.#targetPickedFacePin?.destroy()
      this.#targetPickedFacePin = new FacePin( XeokitMediator.viewer.scene, {
        trianglesPositions: pickedFaceTriangles,
      })

      const shortestSegment = geometry.pointToFaceMeasurement.findShortestSegmentBetweenPointAndFace(this.#firstPoint, pickedFaceEdges)
      XeokitMediator.DistanceMeasurement.createDistanceMeasurement({
        originWorldPos: this.#firstPoint,
        targetWorldPos: shortestSegment[0],
        billboard: geometry.utils.arePointsEqual(this.#firstPoint, shortestSegment[0], 0.00001) ? 'spherical' : 'none'
      })
    }
  }

  static #activateInputListeners() {
    const input = XeokitMediator.viewer.scene.input

    input.off(this.#mouseDownListenerId)
    input.off(this.#mouseUpListenerId)

    this.#mouseDownListenerId = input.on('mousedown', (coords) => {
      this.#mouseDownCanvasX = coords[0]
      this.#mouseDownCanvasY = coords[1]
      this.#mouseDownLeft = input.mouseDownLeft
      this.#mouseDownRight = input.mouseDownRight
    })

    this.#mouseUpListenerId = input.on('mouseup', (coords) => {
      if (
        coords[0] > this.#mouseDownCanvasX + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[0] < this.#mouseDownCanvasX - MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] > this.#mouseDownCanvasY + MOUSE_CANVAS_CLICK_TOLERANCE ||
        coords[1] < this.#mouseDownCanvasY - MOUSE_CANVAS_CLICK_TOLERANCE 
      ) {
        this.#mouseDownLeft = false
        this.#mouseDownRight = false
        return
      }

      let pickResult = null

      if (this.#mouseDownLeft) {
        if (this.#pickingPoint) {
          pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
            canvasPos: coords,
            pickSurface: true,
          })
        } 
        else {
          pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
            canvasPos: coords,
            pickSurface: true,
            needPickedFace: true,
          })
        }

        if (pickResult) {
          if (pickResult.entity.model.isCollisionIntersection) {
            AlertService.info({info: i18n.t('viewer.distanceMeasurement.collisionIntersectionException')})
            return
          }

          this.#pick(pickResult)
        }
      }

      this.#mouseDownLeft = false
      this.#mouseDownRight = false
    })
  }

  static #activateHoverListener() {
    const cameraControl = XeokitMediator.viewer.cameraControl
    this.#vertexPin = new VertexPin( XeokitMediator.viewer.scene, { visible: false } )

    let nearestCorner = null

    this.#mouseHoverListenerId = cameraControl.on('hover', (event) => {
      if (this.#mouseDownLeft || this.#mouseDownRight) {
        this.#vertexPin.setVisible(false)
        return
      }

      let pickResult = null
      if (this.#pickingPoint) {
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: event.canvasPos,
        })
      } 
      else {
        pickResult = XeokitMediator.ScenePick.highPrecisionPickResult({
          canvasPos: event.canvasPos,
          needPickedFace: true,
        })
      }

      if (!pickResult) { return }
      if (pickResult.isSectionControl) return
      if (pickResult.entity.model.isCollisionIntersection) {
        AlertService.info({info: i18n.t('viewer.distanceMeasurement.collisionIntersectionException')})
        cameraControl.off(this.#mouseHoverListenerId)
        return
      }
      if (pickResult.entity?.meshes[0]?.id?.toString().includes('pointsMesh')) { return } 

      if (this.#pickingPoint) {
        let edges = []
        pickResult.entity.meshes.forEach((mesh) => {
          if (mesh.edgeIndices) edges = [...edges, ...geometry.extraction.getEdgesByMesh(mesh)]
        })
        nearestCorner = geometry.nearestCoordFinder.findNearestCornerByEdges(pickResult.worldPos, edges)

        const scale = geometry.math.distance(XeokitMediator.viewer.camera.eye, nearestCorner) * DISTANCE_COEFF
        this.#vertexPin.update({
          position: nearestCorner,
          scale: [scale, scale, scale]
        })
        this.#vertexPin.setVisible(true)
      } 
      else {
        this.#facePin?.destroy()
        const trianglesPositions = pickResult.trianglesPositions
        this.#facePin = new FacePin( XeokitMediator.viewer.scene, {
          trianglesPositions: trianglesPositions,
          edges: false
        })
      }
    })
  }
}
